import React, { useState, useEffect } from "react"
import useEventListener from "../custom/use-event-listener"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import useLockBodyScroll from "../custom/use-lock-body-scroll"
import { Button } from "./buttons"

import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import Consulta from "./headerConsulta"

import logo from "../images/svg/IPMD_logoHorizontal.svg"
import logoDark from "../images/svg/IPMD_logoHorizontalDark.svg"
import blue from "../images/svg/menu_filter.svg"

const Header = ({header}) => {
  const [prevScrollPos, setPrevScrollPos] = useState("");
  const [currentScrollPos, setCurrentScrollPos] = useState("");
  const [screenVH, setScreenVH] = useState("");
  const [small, setSmall] = useState(false);
  const [dark, setDark] = useState(true);

  const [modal, setModal] = useState(false);
  useLockBodyScroll(modal);

  useEffect(() => {
    setPrevScrollPos(window.pageYOffset);
    setScreenVH(window.innerHeight);
  }, []);
  useEventListener("scroll", () => scroller());

  function scroller() {
    setCurrentScrollPos(window.pageYOffset);
    if (prevScrollPos > currentScrollPos || window.pageYOffset <= 0) {
      setSmall(false);
      if(currentScrollPos>screenVH){
        setDark(false);
      }
      else{
        setDark(true);
      }
    } else {
      setSmall(true);
    }
    setPrevScrollPos(currentScrollPos);
  }

  return (
    <StyledHeader>
      <CSSTransition in={dark===true} timeout={350} classNames={"switchLogo"} unmountOnExit>
        <Link to="/" className={small ? "logo smallNav" : "logo"}>
          <img src={logo} alt="logo"/>
        </Link>
      </CSSTransition>
      <CSSTransition in={dark===false} timeout={350} classNames={"switchLogo"} unmountOnExit>
        <Link to="/" className={small ? "logo smallNav" : "logo"}>
          <img src={logoDark} alt="logo"/>
        </Link>
      </CSSTransition>

      <CSSTransition in={modal===true} timeout={350} classNames={"switch"} unmountOnExit>
        <Consulta setModal={setModal}/>
      </CSSTransition>

      <div className={small ? "blur-container smallSize" : "blur-container"}><div className="blur"></div></div>
      <div className={small ? "menu smallSizeAlt" : "menu"}>
        {header.menu.map((data, i)=>(
          data.title==="Consulta" ? 
            <Button regular noHover className={"link"} activeClassName={font.nexaBold + " active"} key={i} onClick={()=>{setModal(!modal)}}>
              <img src={blue} alt="Filter" className="linkFilter"/>
              <p className={font.nexaRegular}>{data.title}</p>
            </Button>
            :
            <Link to={data.link} className={small ? "link smallNav" : "link"} activeClassName={font.nexaBold + " active"} key={i}>
              <img src={blue} alt="Filter" className="linkFilter"/>
              <p className={font.nexaRegular}>{data.title}</p>
            </Link>
        ))}
      </div>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.div`
  width: 100%;
  position: fixed;
  top: 3vw;
  right: 0;
  display: flex;
  align-content: center;
  z-index: 5;
  pointer-events: none;
  @media only screen and (max-width: 768px){
    width: 768px;
  }
  .logo{
    width: calc(115px + 195 * (100vw - 769px) / 1791);
    margin-left: calc(35px + 75 * (100vw - 769px) / 1791);
    transition: opacity 0.6s ease-out;
    pointer-events: all;
    @media only screen and (min-width: 2560px){
      width: 310px;
      margin-left: 110px;
    }
    @media only screen and (max-width: 768px){
      width: 115px;
      margin-left: 35px;
    }
    img{width: 100%;}
  }
  .blur-container{
    height: 65%;
    width: calc(580px + 1020 * (100vw - 769px) / 1791);
    position: absolute;
    top: 17.5%;
    right: 0;
    overflow: hidden;
    transition: transform 0.6s ease-out;
    transform-origin: top right;
    @media only screen and (min-width: 2560px){
      width: 1600px;
    }
    @media only screen and (max-width: 768px){
      width: 580px;
    }
  }
  .blur {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 2000px rgba(40, 40, 40, .2);
    -webkit-backdrop-filter: blur(10px); /* Safari 9+ */
    backdrop-filter: blur(10px); /* Chrome and Opera */
  }
  .menu{
    height: 65%;
    width: calc(580px + 1020 * (100vw - 769px) / 1791);
    position: absolute;
    top: 17.5%;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 calc(10px + 40 * (100vw - 769px) / 1791);
    @media only screen and (min-width: 2560px){
      width: 1600px;
      padding: 0 50px;
    }
    @media only screen and (max-width: 768px){
      width: 580px;
      padding: 0 10px;
    }
  }
  .link{
    color: #fff;
    text-transform: uppercase;
    height: 100%;
    width: 100%;
    position: relative;
    background-size: 0;
    transition: opacity 0.6s ease-out;
    pointer-events: all;
    .linkFilter{
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 0.5s;
    }
    p{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
    :hover{
      .linkFilter{
        opacity: 1;
      }
    }
  }
  .active{
    font-style: italic;
    .linkFilter{
      opacity: 1;
    }
  }
  .smallNav{
    opacity: 0;
    user-select: none;
    pointer-events: none;
  }
  .smallSize{
    transform: scaleX(0.3);
  }


  .switch-enter{
    opacity: 0 !important;
  }
  .switch-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit{
    opacity: 1 !important;
  }
  .switch-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }

  .switchLogo-enter{
    opacity: 0 !important;
    position: absolute;
  }
  .switchLogo-enter-active{
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switchLogo-exit{
    opacity: 1 !important;
  }
  .switchLogo-exit-active{
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`