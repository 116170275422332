import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { CSSTransition } from "react-transition-group"
import useLockBodyScroll from "../custom/use-lock-body-scroll"
import Div100vh from 'react-div-100vh'

import { Button } from "./buttons"
import font from "../fonts/fonts.module.scss"
import styled from "styled-components"

import Consulta from "./headerConsulta"

import logoBig from "../images/svg/IPMD_logoHorizontalDark.svg"
import logoSmall from "../images/svg/logo.svg"
import seta from "../images/svg/setaBigD.svg"

const Header = ({header, logo}) => {
  const [tStart, setTStart] = useState("");
  const [tEnd, setTEnd] = useState("");
  const [screenVH, setScreenVH] = useState("");
  const [dragging, setDragging] = useState(false);
  const blur1 = useRef(null);
  const blur2 = useRef(null);

  const [modal, setModal] = useState(false);
  useLockBodyScroll(modal);

  const [open, setOpen] = useState(false);
  useLockBodyScroll(open);

  useEffect(() => {
    setScreenVH(window.innerHeight);
  }, []);

  function grab(e) {
    setTStart(e.touches[0].clientY);
    setDragging(true);
    setOpen(true);
  }
  function drag(e, close){
    document.querySelector("#shaft").style.transition = "unset";
    let x = 100-((e.touches[0].clientY * 100)/screenVH);
    setTEnd(((e.touches[0].clientY-tStart)*100)/screenVH); //move percentage
    if(dragging){
      if(!close){
        document.querySelector("#shaft").style.transform = "translateY(calc(-" + x + "% - " + tStart +"px)";
        blur1.current.style.backgroundColor = "rgba(255, 255, 255, " + (-x+100)/100 + ")";
        blur1.current.style.transition = "unset";
        blur2.current.style.backgroundColor = "rgba(255, 255, 255, " + (-x+100)/100 + ")";
        blur2.current.style.transition = "unset";
      }
      else{
        if(e.touches[0].clientY<tStart){
          document.querySelector("#shaft").style.transform = "translateY(calc(-" + x + "% + " + (screenVH-tStart) +"px)";
          blur1.current.style.backgroundColor = "rgba(255, 255, 255, " + (((((-x/100)*screenVH)+(screenVH-tStart))/1000)+1) + ")";
          blur1.current.style.transition = "unset";
          blur2.current.style.backgroundColor = "rgba(255, 255, 255, " + (((((-x/100)*screenVH)+(screenVH-tStart))/1000)+1) + ")";
          blur2.current.style.transition = "unset";
        }
      }
    }
  }
  function drop(close){
    setDragging(false);
    document.querySelector("#shaft").style.transition = "transform 300ms ease-out";
    blur1.current.style.transition = "background-color 200ms ease-out";
    blur2.current.style.transition = "background-color 200ms ease-out";
    if(!close){
      if(tEnd<30){
        document.querySelector("#shaft").style.transform = "translateY(-100%)";
        blur1.current.style.backgroundColor = "transparent";
        blur2.current.style.backgroundColor = "transparent";
        setOpen(false);
      }
      else{
        document.querySelector("#shaft").style.transform = "translateY(0%)";
        blur1.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
        blur2.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
      }
    }
    else{
      if(tEnd<-30){
        document.querySelector("#shaft").style.transform = "translateY(-100%)";
        blur1.current.style.backgroundColor = "transparent";
        blur2.current.style.backgroundColor = "transparent";
        setOpen(false);
      }
      else{
        document.querySelector("#shaft").style.transform = "translateY(0%)";
        blur1.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
        blur2.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
      }
    }
  }
  function openMenu(){
    setOpen(true);
    document.querySelector("#shaft").style.transition = "transform 300ms ease-out";
    blur1.current.style.transition = "background-color 200ms ease-out";
    blur2.current.style.transition = "background-color 200ms ease-out";
    blur1.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
    blur2.current.style.backgroundColor = "rgba(255, 255, 255, 1)";
    document.querySelector("#shaft").style.transform = "translateY(0%)";
  }

  return (
    <StyledHeader id="shaft">
      <CSSTransition in={modal===true} timeout={350} classNames={"switch"} unmountOnExit>
        <Consulta setModal={setModal}/>
      </CSSTransition>
      
      <div className="content" onTouchStart={(e)=>{grab(e)}} onTouchMove={(e)=>{drag(e, true)}} onTouchEnd={()=>{drop(true)}}>
        <div className="blur-container" ref={blur1}/>
        <div className="menuContent">
          <Link to={"/"} className="link" activeClassName={font.financierMediumItalic + " active"}>
            <p className={font.nexaRegular + " linkNmb"}>01</p>
            <h1 className={font.financierMediumItalic + " linkTxt"}>Home</h1>
          </Link>
          {header.menu.map((data, i)=>(
            data.title!=="Consulta" ? 
              <Link to={data.link} className="link" activeClassName={font.financierMediumItalic + " active"} key={i}>
                <p className={font.nexaRegular + " linkNmb"}>0{i+2}</p>
                <h1 className={font.financierMediumItalic + " linkTxt"}>{data.title}</h1>
              </Link>
              :
              <Button regular noHover className="consulta" key={i} onClick={()=>{setModal(!modal)}}>
                <h4 className={font.nexaXBold}>SOLICITAR CONSULTA</h4>
                <img src={seta} alt="Consulta"/>
              </Button>
          ))}
        </div>
      </div>

      <button className="pullCut" onTouchStart={(e)=>{grab(e)}} onTouchMove={(e)=>{drag(e)}} onTouchEnd={(e)=>{drop(e)}} onClick={openMenu}>
        <div className="menuPull">
        <div className="blur-container blur-container-pull" ref={blur2}/>
          <div className="logo" style={{width: logo==="big" && "60%"}}>
            <img src={logo==="big" ? logoBig : logoSmall} alt="logo"/>
          </div>
        </div>
      </button>
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled(Div100vh)`
  width: 100%;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  z-index: 5;
  .content{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .menuContent{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .link{
      color: #717171;
      width: 80%;
      display: flex;
      margin: 1.3em 0;
      .linkTxt{
        line-height: 0.7em;
      }
      .linkNmb{
        color: #17d4e3;
        margin-right: 1.5em;
      }
    }
    .consulta{
      color: #17d4e3;
      width: 90%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      bottom: 50px;
      img{
        height: 70%;
      }
    }
  }
  
  .pullCut{
    width: 200px;
    height: calc(200px * 0.6);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    overflow: hidden;
    z-index: 1;
  }
  .menuPull{
    width: 200px;
    height: 200px;
    border-radius: 100%;
  }
  .logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 40%;
    z-index: 2;
    img{width: 100%;}
  }

  .blur-container{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    :after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
    &-pull{
      top: unset;
      bottom: 0;
      border-radius: 100%;
      height: 200px;
      width: 200px;
      :after{
        border-radius: 100%;
      }
    }
  }
`