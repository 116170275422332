import styled, { css } from 'styled-components'

export const Button = styled.button`
  display: block;
  background: transparent;
  user-select: none;
  outline: none;
  color: #17D4E3;
  position: relative;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  
  :active{
    transform: scale(0.9);
  }

  ${props => props.regular && css`
    background: unset;
    border-radius: unset;
    border: unset;
    color: unset;
  `}
  ${props => props.noHover && css`
    :hover{transform: unset;}
    :active{transform: unset;}
  `}


  ${props => props.as && css`
    display: inline-block;
    text-align: center;
    text-decoration: none;
    :active{
      transform: scale(1);
    }
  `}

  ${props => props.primary && css`
    background: purple;
    color: white;
    width: 200px;
  `}

  ${props => props.fff && css`
    background: blue;
    color: black;
  `}
`;